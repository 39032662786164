import React, { useState, useRef, useEffect } from 'react';
import FillX from './FillX';
import './BoxIcon.css';

const FILL_LEVELS = ['none', 'one-quarter', 'half', 'three-quarters', 'full'];

function BoxIcon({ icon, label, fillLevel, onSelect, disabled = false, showIcon = true, menuAlign = 'default' }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const containerRef = useRef(null);
  const menuRef = useRef(null);

  const handleClick = () => {
    if (!disabled) {
      setMenuOpen((prev) => !prev);
    }
  };

  const closeMenu = (e) => {
    if (menuOpen && menuRef.current && !menuRef.current.contains(e.target) && !containerRef.current.contains(e.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeMenu);
    return () => {
      document.removeEventListener('mousedown', closeMenu);
    };
  }, [menuOpen]);

  const menuStyle = {
    position: 'absolute',
    top: '100%', // Align directly below the box
    marginTop: '5px',
    zIndex: 9999,
  };

  if (menuAlign === 'left') {
    menuStyle.right = 0;
    menuStyle.left = 'auto';
  } else {
    menuStyle.left = 0;
  }

  return (
    <div className="box-icon-container" ref={containerRef}>
      <div
        className={`box-icon ${disabled ? 'disabled' : ''}`}
        onClick={handleClick}
      >
        {showIcon && icon}
        <FillX level={fillLevel} />
      </div>
      {menuOpen && (
        <div
          className="fill-menu"
          ref={menuRef}
          style={menuStyle}
        >
          {FILL_LEVELS.map((level) => (
            <div
              key={level}
              className={`fill-option ${fillLevel === level ? 'selected' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                onSelect(level);
                setMenuOpen(false);
              }}
            >
              {level}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default BoxIcon;
