import React, { useState, useEffect, useCallback } from 'react';
import BoxIcon from './components/BoxIcon/BoxIcon';
import { getDaysInMonth, getMonthName, getWeekdayName } from './utils/dateUtils';
import { db } from './utils/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import './styles/App.css';

import { 
  IoMoonOutline, 
  IoBarbellOutline, 
  IoNutritionOutline, 
  IoCheckmarkCircleOutline, 
  IoStatsChartOutline
} from 'react-icons/io5';

const ICONS = [
  { 
    type: 'sleep', 
    icon: <IoMoonOutline />, 
    label: 'Sleep', 
    explanations: {
      none: '<6 hours',
      'one-quarter': '6 to 6.5 hours',
      half: '6.5 to 7 hours',
      'three-quarters': '7 to 7.5 hours',
      full: '>7.5 hours'
    },
    color: '#1f77b4'
  },
  { 
    type: 'exercise', 
    icon: <IoBarbellOutline />, 
    label: 'Exercise', 
    explanations: {
      none: 'no exercise',
      'one-quarter': '0.5 hr light',
      half: '1 hr light or 0.5 hr moderate',
      'three-quarters': '2 hr light or 1 hr moderate',
      full: '0.5 hr vigorous or 1 hr moderate'
    },
    color: '#ff7f0e'
  },
  { 
    type: 'fruits', 
    icon: <IoNutritionOutline />, 
    label: 'Fruits/Vege', 
    explanations: {
      none: '<2 servings',
      'one-quarter': '2 servings',
      half: '3 servings',
      'three-quarters': '4 servings',
      full: '>= 5 servings'
    },
    color: '#2ca02c'
  },
  { 
    type: 'healthy', 
    icon: <IoCheckmarkCircleOutline />, 
    label: 'Healthy Eating', 
    explanations: {
      none: 'unhealthy',
      'one-quarter': 'one meal partially healthy',
      half: 'one meal healthy',
      'three-quarters': 'one meal healthy, one meal partially healthy',
      full: 'two meals healthy'
    },
    color: '#d62728'
  },
];

const FILL_VALUES = {
  none: 0,
  'one-quarter': 0.25,
  half: 0.5,
  'three-quarters': 0.75,
  full: 1.0,
};

function App() {
  const today = new Date();
  const todayString = today.toDateString(); 
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());

  const [monthData, setMonthData] = useState({});
  const [monthSelectorOpen, setMonthSelectorOpen] = useState(false);
  const [analyticsOpen, setAnalyticsOpen] = useState(false);

  const daysInMonth = getDaysInMonth(currentYear, currentMonth);
  const yearMonthKey = `${currentYear}-${currentMonth}`;
  const days = monthData[yearMonthKey] || [];

  const loadMonthData = useCallback(async () => {
    const docRef = doc(db, "monthlyData", yearMonthKey);
    const docSnap = await getDoc(docRef);
    let loadedData = [];
    if (docSnap.exists()) {
      loadedData = docSnap.data().days || [];
    } else {
      // Create dates at noon to avoid timezone issues
      loadedData = Array.from({ length: daysInMonth }, (_, i) => {
        const date = new Date(currentYear, currentMonth, i + 1, 12);
        return {
          date: date.toISOString(),
          highlight: '',
          weight: '',
          boxes: {
            sleep: 'none',
            exercise: 'none',
            fruits: 'none',
            healthy: 'none'
          }
        };
      });
    }

    setMonthData(prev => ({ ...prev, [yearMonthKey]: loadedData }));
  }, [currentYear, currentMonth, daysInMonth, yearMonthKey]);

  useEffect(() => {
    loadMonthData();
  }, [loadMonthData]);

  useEffect(() => {
    const saveData = async () => {
      if (days.length === daysInMonth) {
        await setDoc(doc(db, "monthlyData", yearMonthKey), {
          days
        });
      }
    };
    saveData();
  }, [days, yearMonthKey, daysInMonth]);

  const handleHighlightChange = (index, value) => {
    const updated = [...days];
    updated[index].highlight = value;
    setMonthData(prev => ({ ...prev, [yearMonthKey]: updated }));
  };

  const handleWeightChange = (index, value) => {
    const updated = [...days];
    updated[index].weight = value;
    setMonthData(prev => ({ ...prev, [yearMonthKey]: updated }));
  };

  const handleBoxChange = (index, boxType, fillLevel) => {
    const updated = [...days];
    updated[index].boxes[boxType] = fillLevel;
    setMonthData(prev => ({ ...prev, [yearMonthKey]: updated }));
  };

  const prevMonth = () => {
    const m = currentMonth - 1;
    if (m < 0) {
      setCurrentYear(prev => prev - 1);
      setCurrentMonth(11);
    } else {
      setCurrentMonth(m);
    }
  };

  const nextMonth = () => {
    const m = currentMonth + 1;
    if (m > 11) {
      setCurrentYear(prev => prev + 1);
      setCurrentMonth(0);
    } else {
      setCurrentMonth(m);
    }
  };

  const handleMonthYearSelect = (year, month) => {
    setCurrentYear(year);
    setCurrentMonth(month);
    setMonthSelectorOpen(false);
  };

  const monthOptions = Array.from({ length: 12 }, (_, m) => ({ monthIndex: m, name: getMonthName(m) }));

  const [headerTooltip, setHeaderTooltip] = useState({ visible: false, x: 0, y: 0, label: '', explanations: {} });

  const showHeaderTooltip = (e, label, explanations) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const scrollX = window.scrollX;
    const scrollY = window.scrollY;
    const tooltipWidth = 250;

    let tooltipX = rect.left + scrollX + rect.width / 2;
    // Clamp to avoid going off-screen
    tooltipX = Math.max(tooltipWidth/2 + 10, Math.min(window.innerWidth - tooltipWidth/2 - 10, tooltipX));

    const tooltipY = rect.bottom + scrollY + 8;

    setHeaderTooltip({
      visible: true,
      x: tooltipX,
      y: tooltipY,
      label,
      explanations
    });
  };

  const hideHeaderTooltip = () => {
    setHeaderTooltip({ visible: false, x: 0, y: 0, label: '', explanations: {} });
  };

  const isSameMonthYear = (today.getFullYear() === currentYear && today.getMonth() === currentMonth);

  // Analytics Calculations
  const iconScores = ICONS.map(icon => {
    const totalScore = days.reduce((sum, d) => sum + (FILL_VALUES[d.boxes[icon.type]] || 0), 0);
    const percentage = (totalScore / daysInMonth) * 100;
    return { ...icon, totalScore, percentage };
  });

  const totalScoreSum = iconScores.reduce((sum, icon) => sum + icon.totalScore, 0);
  const overallPercentage = (totalScoreSum / (daysInMonth * ICONS.length)) * 100;

  const getPercentageColor = (pct) => {
    if (pct < 25) {
      return '#8B0000'; // dark red
    } else if (pct < 50) {
      return '#B8860B'; // dark yellow
    } else {
      return '#006400'; // dark green
    }
  };

  // Make graph bigger on desktop
  const width = 400;
  const height = 150;
  const margin = {top: 20, right: 40, bottom: 20, left: 40}; 
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const axisFontSize = '10px';

  // Habits data
  const habitsPaths = ICONS.map(icon => {
    const values = days.map((d, i) => {
      const val = FILL_VALUES[d.boxes[icon.type]] || 0;
      const x = margin.left + (daysInMonth === 1 ? 0 : (i/(daysInMonth-1))*innerWidth);
      const y = margin.top + innerHeight - (val * innerHeight);
      return {x,y};
    });
    const d = values.map((p,i)=> (i===0 ? `M${p.x},${p.y}` : `L${p.x},${p.y}`)).join(' ');
    return { icon, d };
  });

  // Weight data with buffer
  const weightValues = days.map((d,i) => {
    const w = parseFloat(d.weight);
    return Number.isNaN(w) ? null : { day: i, val: w };
  }).filter(v => v !== null);

  let weightPath = null;
  let weightMin = 0;
  let weightMax = 100;
  if (weightValues.length > 0) {
    const rawMin = Math.min(...weightValues.map(v => v.val));
    const rawMax = Math.max(...weightValues.map(v => v.val));
    if (rawMin === rawMax) {
      // If all weights same
      weightMin = rawMin - 2;
      weightMax = rawMax + 2;
    } else {
      const range = rawMax - rawMin;
      weightMin = rawMin - (range * 0.1);
      weightMax = rawMax + (range * 0.1);
    }

    const wValues = weightValues.map((v) => {
      const x = margin.left + (daysInMonth === 1 ? 0 : (v.day/(daysInMonth-1))*innerWidth);
      const y = margin.top + innerHeight - ((v.val - weightMin)/(weightMax - weightMin))*innerHeight;
      return {x,y};
    });
    weightPath = wValues.map((p,i)=> (i===0 ? `M${p.x},${p.y}` : `L${p.x},${p.y}`)).join(' ');
  }

  // Y ticks for habits (left axis)
  const habitsTicks = [0,0.25,0.5,0.75,1.0];

  // Y ticks for weight (right axis)
  let weightTicks = [];
  if (weightValues.length > 0) {
    const steps = 5; // number of intervals
    const stepSize = (weightMax - weightMin) / steps;
    for (let i = 0; i <= steps; i++) {
      weightTicks.push(weightMin + i*stepSize);
    }
  }

  // Visibility state for each icon line and weight line
  const [visibleIcons, setVisibleIcons] = useState(() => {
    // By default, everything visible
    const v = {};
    ICONS.forEach(i => v[i.type] = true);
    v['weight'] = true; // for weight line
    return v;
  });

  const toggleVisibility = (type) => {
    setVisibleIcons(prev => ({ ...prev, [type]: !prev[type] }));
  };

  return (
    <div className="app-container">
      <header className="header" style={{ position: 'relative' }}>
        <button className="nav-button" onClick={prevMonth}>&lt;</button>
        <div className="month-display" onClick={() => setMonthSelectorOpen(!monthSelectorOpen)}>
          {getMonthName(currentMonth)} {currentYear}
          {monthSelectorOpen && (
            <div className="month-selector">
              {monthOptions.map((opt) => (
                <div 
                  key={opt.monthIndex} 
                  className="month-option" 
                  onClick={(e) => { 
                    e.stopPropagation();
                    handleMonthYearSelect(currentYear, opt.monthIndex); 
                  }}
                >
                  {opt.name} {currentYear}
                </div>
              ))}
            </div>
          )}
        </div>
        <button className="nav-button" onClick={nextMonth}>&gt;</button>

        <div style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
          <IoStatsChartOutline
            style={{ cursor: 'pointer', fontSize: '20px' }}
            onClick={() => setAnalyticsOpen(!analyticsOpen)}
          />
        </div>
      </header>

      {analyticsOpen && (
        <div className="analytics-section">
          <div className="analytics-left">
            <div className="analytics-scores-container">
              {iconScores.map(iconScore => (
                <div className="analytics-score-row" key={iconScore.type}>
                  <div className="analytics-icon">{iconScore.icon}</div>
                  <div className="analytics-label">{iconScore.label}</div>
                  <div 
                    className="analytics-score"
                    style={{ color: getPercentageColor(iconScore.percentage) }}
                  >
                    {iconScore.totalScore.toFixed(2)} / {daysInMonth} ({iconScore.percentage.toFixed(1)}%)
                  </div>
                </div>
              ))}
              <div className="analytics-score-row total-row">
                <div className="analytics-icon"></div>
                <div className="analytics-label">Total</div>
                <div 
                  className="analytics-score"
                  style={{ color: getPercentageColor(overallPercentage) }}
                >
                  {overallPercentage.toFixed(1)}%
                </div>
              </div>
            </div>
          </div>
          <div className="analytics-right">
            <svg width={width} height={height} className="analytics-graph">
              {/* Habits grid lines */}
              {habitsTicks.map(level => {
                const lineY = margin.top + innerHeight - (level * innerHeight);
                return <line key={level} x1={margin.left} y1={lineY} x2={width - margin.right} y2={lineY} stroke="#ddd" strokeDasharray="2,2" />;
              })}

              {/* Weight grid lines (on right axis) */}
              {weightValues.length > 0 && weightTicks.map((wval, i) => {
                const wvalRatio = (wval - weightMin)/(weightMax - weightMin);
                const lineY = margin.top + innerHeight - (wvalRatio * innerHeight);
                return <line key={'w'+i} x1={margin.left} y1={lineY} x2={width - margin.right} y2={lineY} stroke="#eee" strokeDasharray="1,2" />;
              })}

              {/* Draw habits lines if visible */}
              {habitsPaths.map(lp => (
                visibleIcons[lp.icon.type] && (
                  <path key={lp.icon.type} d={lp.d} stroke={lp.icon.color} fill="none" strokeWidth="2" />
                )
              ))}
              {/* Draw weight line if visible and exists */}
              {weightPath && visibleIcons['weight'] && (
                <path d={weightPath} stroke="#000" fill="none" strokeWidth="2" />
              )}

              {/* Title */}
              <text x={width/2} y={margin.top/2} textAnchor="middle" fontSize={axisFontSize} fontWeight="bold">
                Healthy Habits & Weight
              </text>

              {/* Left axis ticks and labels */}
              {habitsTicks.map((level) => {
                const lineY = margin.top + innerHeight - (level * innerHeight);
                return (
                  <text key={'h'+level} x={margin.left - 8} y={lineY+3} fontSize={axisFontSize} textAnchor="end">{level}</text>
                );
              })}

              {/* Right axis ticks and labels */}
              {weightValues.length > 0 && weightTicks.map((wval) => {
                const wvalRatio = (wval - weightMin)/(weightMax - weightMin);
                const lineY = margin.top + innerHeight - (wvalRatio * innerHeight);
                return (
                  <text key={'r'+wval} x={width - margin.right + 5} y={lineY+3} fontSize={axisFontSize} textAnchor="start">
                    {wval.toFixed(1)}
                  </text>
                );
              })}

              {/* Left axis label */}
              <text x={margin.left - 20} y={margin.top + innerHeight/2 - 10} textAnchor="middle" fontSize={axisFontSize}
                transform={`rotate(-90,${margin.left - 20},${margin.top+innerHeight/2})`}>Score (0 to 1)</text>

              {/* Right axis label (Weight) */}
              {weightValues.length > 0 && (
                <text x={width - (margin.right/2)} y={margin.top + innerHeight/2 - 10} textAnchor="middle" fontSize={axisFontSize}
                  transform={`rotate(90,${width - (margin.right/2)},${margin.top+innerHeight/2})`}>Weight</text>
              )}

              {/* Horizontal Axis Label */}
              <text x={width/2} y={height - (margin.bottom/4)} textAnchor="middle" fontSize={axisFontSize}>Days</text>
            </svg>
            <div className="analytics-legend">
              {ICONS.map(icon => {
                const isVisible = visibleIcons[icon.type];
                return (
                  <div key={icon.type} className="analytics-legend-item" onClick={() => toggleVisibility(icon.type)}
                    style={{ cursor: 'pointer', opacity: isVisible ? 1 : 0.5 }}
                  >
                    <svg width="10" height="10">
                      <rect width="10" height="10" fill={icon.color} />
                    </svg>
                    <span style={{ fontSize: axisFontSize }}>{icon.label}</span>
                  </div>
                );
              })}
              {weightValues.length > 0 && (
                <div className="analytics-legend-item" onClick={() => toggleVisibility('weight')}
                  style={{ cursor: 'pointer', opacity: visibleIcons['weight'] ? 1 : 0.5 }}
                >
                  <svg width="10" height="10">
                    <rect width="10" height="10" fill="#000" />
                  </svg>
                  <span style={{ fontSize: axisFontSize }}>Weight</span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="tracking-table">
        <div className="table-header">
          <div className="day-header">Day</div>
          <div className="highlight-header">Highlight</div>
          <div className="weight-header">Weight</div>
          {ICONS.map((iconItem) => (
            <div 
              key={iconItem.type} 
              className="header-icon-cell"
              onMouseEnter={(e) => showHeaderTooltip(e, iconItem.label, iconItem.explanations)}
              onMouseLeave={hideHeaderTooltip}
            >
              {iconItem.icon}
            </div>
          ))}
        </div>
        <div className="table-body">
          {days.map((dayData, index) => {
            const dateObj = new Date(dayData.date);
            const weekday = getWeekdayName(dateObj);
            const dayNum = dateObj.getDate();
            const isToday = isSameMonthYear && dateObj.toDateString() === todayString;

            const highlightPlaceholder = isToday ? "Add highlight..." : "";
            const weightPlaceholder = isToday ? "Weight" : "";

            return (
              <div className={`row ${isToday ? 'today-row' : ''}`} key={index}>
                <div className="day-cell">
                  <span className="weekday">{weekday}</span>
                  <span className="daynum">{dayNum}</span>
                </div>
                <div className="highlight-cell">
                  <input 
                    type="text" 
                    className="highlight-input"
                    placeholder={highlightPlaceholder}
                    value={dayData.highlight}
                    onChange={(e) => handleHighlightChange(index, e.target.value)}
                  />
                </div>
                <div className="weight-cell">
                  <input 
                    type="number"
                    className="weight-input"
                    placeholder={weightPlaceholder}
                    value={dayData.weight}
                    onChange={(e) => handleWeightChange(index, e.target.value)}
                  />
                </div>
                {ICONS.map(iconItem => (
                  <div className="icon-box-cell" key={iconItem.type}>
                    <BoxIcon
                      label={iconItem.label}
                      fillLevel={dayData.boxes[iconItem.type]}
                      onSelect={(level) => handleBoxChange(index, iconItem.type, level)}
                      showIcon={false}
                    />
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
      {headerTooltip.visible && (
        <div 
          className="header-tooltip"
          style={{ 
            position: 'fixed', 
            left: headerTooltip.x, 
            top: headerTooltip.y, 
            background: '#fff',
            border: '1px solid #ccc',
            color: '#333', 
            padding: '8px 10px',
            borderRadius: '4px',
            fontSize: '14px',
            zIndex: 9999,
            width: '250px',
            lineHeight: '1.4',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
          }}
        >
          <div style={{ fontWeight: 'bold', marginBottom: '6px' }}>
            {headerTooltip.label}
          </div>
          {Object.entries(headerTooltip.explanations).map(([key, val]) => (
            <div key={key}><b>{key}</b>: {val}</div>
          ))}
        </div>
      )}
    </div>
  );
}

export default App;
